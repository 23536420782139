import React from 'react'
import '../Components/Component-css/UserfooterBar.css'
import { Link, useHistory } from 'react-router-dom';

export const UserFooterBar = () => {
  return (
        <>
            <div className='footer_list'>
                <Link to={"/Profile"}>
                    <div className='profile_icon icon_zindex'>
                        <i class="fa-solid fa-user"></i>
                        <p className='title_name'>Profile</p>
                    </div>
                </Link>
                <Link to={"/wallet"}>
                    <div className='profile_icon icon_zindex'>
                        <i class="fa-solid fa-wallet"></i>
                        <p className='title_name'>Wallet</p>
                    </div>
                </Link>

                <div className='game_btn_main'>
                    <Link to={"/landing"}>
                        <div className='profile_icon profile_btn_box'>
                            <button className='profile_game_btn'>
                                <i class="fa-solid fa-dice-five"></i>
                            </button>
                        </div>
                    </Link>
                </div>
      
                <Link to={"/Gamehistory"}>
                    <div className='profile_icon profile_icon_margin_left icon_zindex'>
                        <i class="fa-solid fa-trophy"></i>
                        <p className='title_name'>History</p>
                    </div>
                </Link>
                <Link to={"/refer"}>
                    <div className='profile_icon icon_zindex'>
                        <i class="fa-solid fa-user-plus"></i>
                        <p className='title_name'>Refer</p>
                    </div>
                </Link>
            </div>

        </>
    )
}

