import React, { useEffect, useState } from "react";
import css from "../css/Download.module.css";
import Rightcontainer from "../Components/Rightcontainer";

const Download = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }

  return (
    <>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="cxy flex-column " style={{ paddingTop: "16%" }}>
          <a></a>
          <img
            src={process.env.PUBLIC_URL + "/Images/download.png"}
            width="280px"
            alt=""
          />

          <button
            onClick={onClick}
            className=" btn-success btn-sm btn-download "
          >
            <img
              src="https://jaipurludo.com/Images/android.png"
              alt=""
              style={{ marginRight: 10 }}
              width="15px"
            />
            DOWNLOAD OUR APP
            <img
              src="https://jaipurludo.com/Images/dowloadIcon.png"
              alt=""
              style={{ marginLeft: 10 }}
              width="13px"
            />
          </button>
          
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};

export default Download;
