import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Link} from 'react-router-dom'
import { Collapse } from 'react-bootstrap';
import Header from "../Components/Header";
import '../Components/Component-css/Winners.css';


export default function Ranking() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    var baseUrl ;
    if (nodeMode === "development") {
        baseUrl = beckendLocalApiUrl;
    } else {
        baseUrl = beckendLiveApiUrl
    }
    const [open, setOpen] = useState(false);
    
    const [currentMonth, setCurrentMonth] = useState(true);
    const [lastMonth, setLastMonth] = useState(false);
    
    const [userAllData, setUserAllData] = useState();
    const [WebSitesettings, setWebsiteSettings] = useState("");
    
    const [highRanked, sethighRanked] = useState("");
    const [listItems, setlistItems] = useState("");
    
    const showCurrentWinner = () => {
        sethighRanked("");
        setlistItems("");
        setCurrentMonth(true);
        setLastMonth(false);
        fetchData();
    }
    
    const showLastWinner = () => {
        sethighRanked("");
        setlistItems("");
        setCurrentMonth(false);
        setLastMonth(true);
        fetchDataLastmonth();
    }
    
 
    
    const fetchData = async () => {
        const response = await fetch(baseUrl + "website/userrank");
        const data = await response.json();
        console.log(data);
        
            const highRanked2 = data.slice(0, 3);
            sethighRanked(highRanked2);
    // Remaining items
            const listItems2 = data.slice(3);
            setlistItems(listItems2);
    
        return setWebsiteSettings(data);
    }
    
    const fetchDataLastmonth = async () => {
        const response = await fetch(baseUrl + "website/userranklastmonth");
        const data = await response.json();
        console.log(data);
        
            const highRanked2 = data.slice(0, 3);
            sethighRanked(highRanked2);
    // Remaining items
            const listItems2 = data.slice(3);
            setlistItems(listItems2);
    
        return setWebsiteSettings(data);
    }

    const role = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        await axios.get(baseUrl + `me`, { headers })
            .then((res) => {
                setUserAllData(res.data)
            })
            .catch(e => {
                if (e.response.status == 401) {
                    localStorage.removeItem('token');
                }
            })
    }
    


    useEffect(() => {
        let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if (!access_token) {
            //window.location.reload()
        }
        role();
        fetchData();
    }, [])

    return (
        <>
            
            <Header user={userAllData} />
            <div className='leftContainer'>
                <div className='main-area' style={{ paddingTop: '60px' }}>
                </div>
                
        {/* Start */}
    <div className="leftContainer" style={{minHeight:'100vh',height:'100%'}}>
        {/* pagination */}

      <div itemID="winner_section">
        <div className="button_section row">
            <button onClick={showCurrentWinner} className="current_month col-5">
                Current Month
            </button>
            <button onClick={showLastWinner} className="last_month col-5"> 
                Last Month
            </button>
        </div>
      </div>
         
      <div className="winners_btn_container">
          <button className="winners">Leaderboard</button>
      </div>

      <div className="profile_section row" itemID="current_winner">
            {highRanked && 
              <div className="second_winner col-3 text-center">
                <div className="winner_place">
                    <img src="./2_no.png" className="winner_place_img" alt="" width="30px" />
                </div>
                <div className="winner_profile second_profile">
                    <div className="second_profile_sub">
                        <img src="./Images/avatars/Avatar1.png" alt="" />
                    </div>
                </div>
                <div className="winner_username">
                    {highRanked[1].userDetails.map(user => (
                        user.Name
                    ))}
                    
                    
                </div>
                <div className="winner_amount">
                    Rs.{highRanked[1].monthlyWinnAmount}
                </div>
                <div className="prize_amount">
                    <img src="./prize_cup.png" alt="" /> 
                    <span>Total Play: {highRanked[1].count}</span>  
                </div>
                </div>
            }
               
                
                {highRanked && 
                  <div className="first_winner col-6 text-center">
                    <div className="winner_place">
                        <img src="./pngwing.com.png" alt="" width="60px" />
                    </div>
                    <div className="winner_profile first_profile">
                        <div className="first_profile_sub">
                            <img src="./Images/avatars/Avatar2.png" alt="" />
                        </div>
                    </div>
                    <div className="winner_username">
                        {highRanked[0].userDetails.map(user => (
                        user.Name
                    ))}
                    </div>
                    <div className="winner_amount">
                    Rs.{highRanked[0].monthlyWinnAmount}
                    </div>
                    <div className="prize_amount">
                        <img src="./prize_cup.png" alt="" /> 
                    <span>Total Play: {highRanked[0].count}</span>  
                    </div>
                  </div>
                }
                 
                {highRanked && 
                  <div className="third_winner col-3 text-center">
                    <div className="winner_place">
                        <img src="./3_no.png" className="winner_place_img" alt="" width="30px" />
                    </div>
                    <div className="winner_profile third_profile">
                        <div className="third_profile_sub">
                            <img src="./Images/avatars/Avatar1.png" alt="" />
                        </div>
                    </div>
                    <div className="winner_username">
                    {highRanked[2].userDetails.map(user => (
                        user.Name
                    ))}
                    </div>
                    <div className="winner_amount">
                        Rs.{highRanked[2].monthlyWinnAmount}
                    </div>
                    <div className="prize_amount">
                        <img src="./prize_cup.png" alt="" /> 
                    <span>Total Play: {highRanked[2].count}</span>  
                    </div>
                  </div>
                }
                  
      </div>

    {listItems && listItems.map(item => (
      <div className="small_winner row" key={item._id}>
          <div className="winning_place col-1 text-center">
              <p>Winning Bats: {item.count}</p>
          </div>  
          <div className="winner_profile_pic col-2 text-center">
              <img src="./picture_1.jpg" alt="" width="60px" />
          </div>        
          <div className="winner_info col-5">
              <p> {item.userDetails.map(user => (
                  user.Name
                ))}</p>
              <p className="expected_prize">Rs.50 to Rs.200 Expected winnings bonus</p>
          </div>
          <div className="winner_amount col-3">
              <p>Rs.{item.monthlyWinnAmount}</p>
          </div>
      </div>
      ))}

    </div>
        {/* end */}
    
            </div>
        </>
    )
}